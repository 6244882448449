<template>
  <div class="d-flex flex-column">
    <hs-timeline :loading="loading" :placeholders="3" :steps="steps" />
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'Timeline',
  props: {
    loading: Boolean,
    data: Array,
  },
  data() {
    return {
      steps: [],
    };
  },
  watch: {
    data(value) {
      this.steps = this.normalizeData(value);
    },
  },
  methods: {
    normalizeData(data) {
      let steps = [];

      data.map(l => {
        steps.push({
          icon: l.completed ? 'check' : 'clock',
          title: this.$t(
            `sparkmembers.members-details.enrollment-details.access-history.components.timeline.lessons_progresses.${
              l.completed ? 'completed' : 'not-completed'
            }`,
            { title: l.lesson.title }
          ),
          description: this.$t(
            'sparkmembers.members-details.enrollment-details.access-history.components.timeline.lessons_progresses.description',
            {
              date: dayjs(l.updated_at).format('D, MMM, YYYY - HH:mm'),
            }
          ),
          variant: l.completed ? 'purple' : 'secondary-purple',
          updated_at: l.updated_at,
        });
      });

      return steps.sort(
        (a, b) => dayjs(b.updated_at).format('YYYYMMDDHHmm') - dayjs(a.updated_at).format('YYYYMMDDHHmm')
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .bg-secondary-purple {
  background-color: #ead3fe !important;
  i {
    color: #4d1aa1 !important;
  }
}
</style>
